<template>
  <div class="vBlockConversionStatistics">
    <vLightPreloader
      v-show="isLoading"
      :fixed="false"
      :transparent="true"
    />
    <div
      class="vBlockConversionStatistics__period"
      :class="{ noIndent: selectedNumberDays > 30 }"
    >
      <div class="vBlockConversionStatistics__period-label">
        <div
          v-if="!period"
          class="vBlockConversionStatistics__period-placeholder"
        >
          Выберите дату или диапазон дат
        </div>
        <div
          v-else
          class="vBlockConversionStatistics__period-placeholder"
          :class="{ active: period }"
        >
          Дата
        </div>

        <div class="vBlockConversionStatistics__period-value">
          {{ period ? convertUTC : '' }}
        </div>
        <VIconCalendar :period="period" />
      </div>
      <vDatePicker
        v-model="period"
      />
    </div>

    <div
      v-if="selectedNumberDays > 30"
      class="vBlockConversionStatistics__period-exceeded"
    >
      Выберите период, который будет менее 31 дня
    </div>

    <vButton
      :disabled="!period || selectedNumberDays > 30"
      @click="uploadTable"
    >
      Выгрузить таблицу
    </vButton>

    <vButton
      v-if="period"
      theme="white"
      @click="onReset"
    >
      Сбросить
    </vButton>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vDatePicker from '@/components/v-date-picker.vue'
import { convertUtc } from '@/utils/convertUtc'
import { filterPeriod } from '@/utils/filterDate'
import vLightPreloader from '@/components/v-light-preloader'
import vButton from '@/components/v-button'
import VIconCalendar from '@/assets/images/iconAdmin/v-icon-calendar'
export default {
  name: 'VBlockConversionStatistics',
  components: {
    vDatePicker,
    vButton,
    VIconCalendar,
    vLightPreloader,
  },
  data() {
    return {
      period: null,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
    convertUTC() {
      return this.period ? convertUtc(this.period) : ''
    },

    selectedNumberDays() {
      if (this.period) {
        const dayOne = new Date(this.period[0])
        const dayTwo = new Date(this.period[1])
        const finalDay = Math.ceil(
          Math.abs(dayTwo.getTime() - dayOne.getTime()) / (1000 * 3600 * 24)
        )
        return finalDay
      }
      return ''
    },
  },
  mounted() {
    const body = document.querySelector('body')
    body.classList.add('calendarPage')
  },

  unmounted() {
    const body = document.querySelector('body')
    body.classList.remove('calendarPage')
  },
  methods: {
    ...mapActions('statistic', ['EXPORT_CONVERSION_STATISTICS']),

    onReset() {
      this.period = null
    },

    uploadTable() {
      this.isLoading = true

      const dates = filterPeriod(this.period)

      const periodStatistics = {
        token: this.GET_AUTHENTIFICATED,
        from: dates.startDate,
        before: dates.endDate,
      }

      this.EXPORT_CONVERSION_STATISTICS(periodStatistics)

      setTimeout(() => {
        this.period = null
        this.isLoading = false
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
.vBlockConversionStatistics {
  &__period {
    display: flex;
    align-items: center;
    position: relative;
    background-color: #fff;
    border: 1px solid #e6e7eb;
    border-radius: 12px;
    margin-bottom: 20px;
    width: 100%;
    height: 52px;
    overflow: hidden;
    cursor: pointer;
    @media (max-width: 769px) {
      margin-top: 16px;
    }
    &-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 12px;
      font-size: 16px;
      cursor: pointer;
      color: #010d35;
    }
    &-placeholder {
      color: #80869a;
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
      @media (max-width: 420px) {
        font-size: 12px;
      }
    }
    &-value {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #010d35;
      transform: translateY(45%);
    }
    .active {
      top: 8px;
      font-size: 12px;
      transform: translateY(0);
    }
  }
  &__period-exceeded {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #ec4e4e;
    margin: 4px 0 16px 0;
  }
  .noIndent {
    margin: 0;
  }
  .mx-datepicker.mx-datepicker-range {
    width: 100%;
  }
}
</style>

